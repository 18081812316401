<template>
  <div>Public Data works!</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
